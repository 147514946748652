<template>
    <el-divider></el-divider>
    <el-form ref="formRef" :model="formodel" label-width="100px" enctype="multipart/form-data">
        <el-form-item label="所属分类" :model="formodel.roleid">
            <el-tree-select ref="treeselect" v-model="formodel.cateName" @change="selectCurrent" node-key="id"
                :props="treeprops" placeholder="请选择分类" :data="cateList" :render-after-expand="false" />
        </el-form-item>
        <el-form-item label="文章标题">
            <el-input v-model="formodel.title" placeholder="请输入标题(必填)" />
        </el-form-item>
        <el-form-item label="导读">
            <el-input v-model="formodel.description" placeholder="请输入导读" />
        </el-form-item>
        <el-row>
            <el-col :span="8">
                <el-form-item label="来源">
                    <el-input v-model="formodel.resource" placeholder="请输入来源" />
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="作者">
                    <el-input v-model="formodel.author" placeholder="请输入作者" />
                </el-form-item>
            </el-col>
            <el-col :span="4">
                <el-form-item label="发布时间">
                    <el-date-picker v-model="formodel.publishTime" type="date" placeholder="发布时间" format="YYYY/MM/DD" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item label="发布内容">
            <Toolbar style="border-bottom: 1px solid #ccc;width: 100%;" :editor="editor" :defaultConfig="toolbarConfig"
                :mode="mode" />
            <Editor style="height: 200px; width: 100%; overflow-y: hidden;" v-model="formodel.contents"
                :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
        </el-form-item>
        <el-form-item>
            <el-button type="info" @click="onSubmit(0)">保存</el-button>
            <el-button type="primary" @click="onSubmit(1)">发布</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import { ElMessage } from 'element-plus'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    data() {
        return {
            formodel: {
                id: 0,
                title: '',//名称
                cateName: '',
                cateId: '',//分类ID
                firstHead: '',//封面图
                description: '',//导读
                author: '',//手机号码
                resource: '',//状态
                contents: '',
                publishTime: '',
                status: 0
            },
            cateList: [],
            editor: null,
            toolbarConfig: {},
            editorConfig: { placeholder: '请输入内容...', MENU_CONF: {} },
            mode: 'default', // or 'simple'
            treeprops: {
                value: 'value',
                label: 'text'
            }
        }
    },
    created() {
        this.init();
        this.configInit();
    },
    mounted() {

    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        //选中赋值
        selectCurrent(cateId) {
            this.formodel.cateId = cateId
        },
        editForm(item) {
            this.formodel.cateId = item.cateId;
            this.formodel.cateName = item.cateName;
            //延时填充数据
            setTimeout(() => {
                this.$https.get('api/admin/article/get', { params: { id: item.id } }).then(res => {
                    if (res.success) {
                        var result = res.data;
                        this.formodel.id = result.id;
                        this.formodel.title = result.title,//名称
                            this.formodel.firstHead = result.firstHead,//封面图
                            this.formodel.description = result.description,//导读
                            this.formodel.author = result.author,//手机号码
                            this.formodel.resource = result.resource,//状态
                            this.formodel.contents = result.contents,
                            this.formodel.publishTime = result.publishTime,
                            this.formodel.status = result.status
                    }
                })
            }, 100);
        },
        //配置图片上传
        configInit() {
            const token = localStorage.getItem('token');
            let _this = this;
            this.editorConfig.MENU_CONF['uploadImage'] = {
                server: this.$baseUrl + 'api/admin/Upload/UploadCkeditorFile',
                headers: { Authorization: `Bearer ${token}` },
                maxFileSize: 10 * 1024 * 1024, // 10M
                customInsert(result, insertFn) {
                    if (result.success == undefined || !result.success) {
                        switch (result.errorCode) {
                            case 500:
                                ElMessage.error('内部出错了，请联系客服')
                                break;
                            case 413:
                                ElMessage({
                                    message: '很抱歉，您没有该操作权限.',
                                    type: 'warning'
                                })
                                break;
                            case 401:
                                ElMessage(result.msg + ",将在3s后跳转回登陆页...")
                                setTimeout(() => {
                                    router.push('/')
                                }, 3000);
                                break;
                            case -1:
                                ElMessage(result.msg + ",将在3s后跳转回登陆页...")
                                setTimeout(() => {
                                    router.push('/')
                                }, 3000);
                                break;
                            default:
                                ElMessage({
                                    message: result.msg,
                                    type: 'warning'
                                })
                                break;
                        }
                    } else {
                        const { filename, uploaded, url } = result
                        _this.formodel.firstHead = (_this.formodel.firstHead == '' ? url : ''); //获取首图
                        insertFn(url, filename, url)
                    }
                },
                onBeforeUpload(files) {
                    return files; // 将要上传的文件
                },
                onSuccess(file, res) {
                    console.log('onSuccess', file, res)
                },
                onFailed(file, res) {
                    ElMessage('上传失败')
                },
                onError(file, err, res) {
                    ElMessage({
                        message: '文件不能超过10M！'
                    })

                }
            }


            this.editorConfig.MENU_CONF['uploadVideo'] = {
                server: this.$baseUrl + 'api/admin/Upload/UploadVideo',
                headers: { Authorization: `Bearer ${token}` },
                maxFileSize: 30 * 1024 * 1024, // 10M
                customInsert(result, insertFn) {
                    if (result.success == undefined || !result.success) {
                        switch (result.errorCode) {
                            case 500:
                                ElMessage.error('内部出错了，请联系客服')
                                break;
                            case 413:
                                ElMessage({
                                    message: '很抱歉，您没有该操作权限.',
                                    type: 'warning'
                                })
                                break;
                            case 401:
                                ElMessage(result.msg + ",将在3s后跳转回登陆页...")
                                setTimeout(() => {
                                    router.push('/')
                                }, 3000);
                                break;
                            case -1:
                                ElMessage(result.msg + ",将在3s后跳转回登陆页...")
                                setTimeout(() => {
                                    router.push('/')
                                }, 3000);
                                break;
                            default:
                                ElMessage({
                                    message: result.msg,
                                    type: 'warning'
                                })
                                break;
                        }
                    } else {
                        const { filename, uploaded, url } = result
                        insertFn(url, filename, url)
                    }
                },
                onBeforeUpload(files) {
                    return files; // will upload this file
                },
                onSuccess(file, res) {
                    console.log('onSuccess', file, res)
                },
                onFailed(file, res) {
                    ElMessage('上传失败')
                },
                onError(file, err, res) {
                    ElMessage({
                        message: '文件不能超过30M！'
                    })
                }
            }
        },

        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错

        },
        init() {
            let _this = this;
            this.$https.get('api/admin/Category/GetCatetree').then(res => {
                if (res.success) {
                    _this.cateList = res.data;
                }
            })

        },
        onSubmit(status) {
            const _this = this;
            var date = new Date(this.formodel.publishTime);
            _this.formodel.publishTime = (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
            _this.formodel.contents = this.editor.getHtml();
            _this.formodel.status = status;
            this.$https.post('api/Admin/Article/insertOrUpdate', _this.formodel).then(res => {
                if (res.success) {
                    _this.clearForm()
                    _this.$emit('closeDialog');
                }
            })
        },
        //清空
        clearForm() {
            this.formodel.title = '',//名称
                this.formodel.cateId = '',//分类ID
                this.formodel.firstHead = '',//封面图
                this.formodel.description = '',//导读
                this.formodel.author = '',//手机号码
                this.formodel.resource = '',//状态
                this.formodel.contents = '',
                this.formodel.publishTime = '',
                this.formodel.status = ''

        }
    },
    components: {
        Editor,
        Toolbar
    }
}
</script>