<template>
  <el-row class="hz-row" :gutter="24">
    <el-col :span="12">
      <el-button type="primary" @click="addDialog" v-hasPer="['article:insertorupdate']">
        <el-icon style="vertical-align: middle">
          <Plus />
        </el-icon>
        <span style="vertical-align: middle"> 发布文章 </span>
      </el-button>
    </el-col>
    <el-col :span="2">
    </el-col>
    <el-col :span="10">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-tree-select v-model="cateId"   placeholder="请选择分类"  :props="treeprops" :data="data" :render-after-expand="false" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="searchName" v-hasPer="['article:search']" placeholder="输入标题关键字"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="init()" v-hasPer="['article:search']">
            <el-icon style="vertical-align: middle">
              <Search />
            </el-icon>
            <span style="vertical-align: middle"> 搜索 </span>
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="info" @click="clear()" >
            <el-icon><Delete /></el-icon>
            <span > 清除 </span>
          </el-button>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  <el-divider></el-divider>
  <div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="title" label="文章标题" />
      <el-table-column prop="cateName" label="文章分类" />
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-tag :type="scope.row.status === 1 ? 'success' : 'danger'" disable-transitions>{{ scope.row.status == 1 ?
            "已发布"
            : "未发布"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="readCount" label="阅读量" />
      <el-table-column prop="publishTime" label="发布时间" />
      <el-table-column label="操作" with="120">
        <template #default="scope">
          <el-button size="small" v-hasPer="['article:insertorupdate']"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="small" type="danger" v-hasPer="['article:del']"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="pagin">
    <el-pagination small background layout="prev, pager, next" :total="total" class="mt-4"
      @current-change="handleCurrentChange" />
  </div>
  <el-dialog v-model="dialogVisible" :title="dialogTitle" width="80%" draggable>
    <Add @closeDialog="closeDialog" ref="addArticle" v-if="dialogTitle == '新增文章'"></Add>
    <Edit v-else-if="dialogTitle == '编辑文章'" @closeDialog="closeDialog" ref="editArticle"></Edit>
  </el-dialog>
</template>
  
<script>
import { ElMessage } from 'element-plus'
import Add from './add.vue'
import Edit from './edit.vue'
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      small: false,
      background: true,
      tableData: [],
      total: 0,
      dialogVisible: false,
      dialogTitle: '新增文章',
      searchName: '',
      cateId: '',//分类ID
      data: [],
      treeprops: {
        value: 'value',
        label: 'text'
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let _this = this;
      //获取列表
      this.$https.get('api/admin/article/getlist', {
        params: {
          page: _this.currentPage,
          limit: _this.pageSize,
          title: _this.searchName,
          cateid: (_this.cateId==''?0:_this.cateId)
        }
      }).then(res => {
        if (res.success) {
          _this.total = res.count;
          _this.tableData = res.data;
        }
      })

      //获取文章分类
      this.$https.get('api/admin/Category/GetCatetree').then(res => {
        if (res.success) {
          _this.data = res.data
        }
      })
    },
    handleEdit(index, item) {
      this.dialogVisible = true;
      this.dialogTitle = '编辑文章'
      this.$nextTick(() => {
        this.$refs.editArticle.editForm(item);
      })
    },
    handleDelete(index, item) {
      const _this = this;
      this.$https.get('api/admin/article/del', { params: { id: item.id } }).then(res => {
        
        if (res.success) {
          ElMessage({
            type: 'success',
            message: res.msg
          })
          _this.init();
        }
      })
    },
    //点击页码事件
    handleCurrentChange(value) {
      this.currentPage = value
      this.init();
    },
    //弹出新增文章界面
    addDialog() {
      this.dialogVisible = true;
      this.dialogTitle = '新增文章'
    },
    //关闭弹窗
    closeDialog() {
      this.dialogVisible = false;
      this.init();
    },
    //清除搜索条件
    clear(){
       this.cateId=""
       this.searchName=""
    } 
  },
  components: {
    Add,
    Edit
  }
}
</script>
  
<style>
.pagin {
  margin-top: 10px;
}

.el-dialog__body {
  padding-top: 0 !important;
}
</style>